import './Assets/Js/scripts'
import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './Assets/Style/sb_styles.css'
import './Assets/Style/style.scss';
import './App.css';
import Home from "./Components/Pages/Home";
import Nav from "./Components/Includes/Nav";
import SideBar from "./Components/Includes/SideBar";
import Footer from "./Components/Includes/Footer";
import axios from "axios";
import {useEffect, useState} from "react";
import Constant from "./Constant";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./Components/Pages/Auth/Login";
import Auth from "./Auth";
import CategoryCreate from "./Components/Pages/Category/CategoryCreate";
import CategoryList from "./Components/Pages/Category/CategoryList";
import PropertyCreate from "./Components/Pages/Property/PropertyCreate";
import CategoryEdit from "./Components/Pages/Category/CategoryEdit";
import PropertyList from "./Components/Pages/Property/PropertyList";
import PropertyEdit from "./Components/Pages/Property/PropertyEdit";
import ListingTypeCreate from "./Components/Pages/ListingType/ListingTypeCreate";
import ListingTypeList from "./Components/Pages/ListingType/ListingTypeList";
import ListingTypeEdit from "./Components/Pages/ListingType/ListingTypeEdit";
import PropertyTypeCreate from "./Components/Pages/PropertyType/PropertyTypeCreate";
import PropertyTypeList from "./Components/Pages/PropertyType/PropertyTypeList";
import PropertyTypeEdit from "./Components/Pages/PropertyType/PropertyTypeEdit";
import AmenitiesCreate from "./Components/Pages/Amenities/AmenitiesCreate";
import AmenitiesList from "./Components/Pages/Amenities/AmenitiesList";
import AmenitiesEdit from "./Components/Pages/Amenities/AmenitiesEdit";
import GuestFavoritesCreate from "./Components/Pages/GuestFavorites/GuestFavoritesCreate";
import GuestFavoritesList from "./Components/Pages/GuestFavorites/GuestFavoritesList";
import GuestFavoritesEdit from "./Components/Pages/GuestFavorites/GuestFavoritesEdit";
import SafetyItemCreate from "./Components/Pages/SafetyItem/SafetyItemCreate";
import SafetyItemList from "./Components/Pages/SafetyItem/SafetyItemList";
import SafetyItemEdit from "./Components/Pages/SafetyItem/SafetyItemEdit";
import ListingList from "./Components/Pages/Listing/ListingList";

function App() {


    const [url, setUrl] = useState('')
    useEffect(() => {
        setUrl(window.location.pathname)
        checkAuth()
        setTimeout(()=>{
            checkAuth()
        }, 900000)
    }, [])

    const [auth, setAuth] = useState(false);
    const checkAuth = () => {
        let url = window.location.pathname
        const token = localStorage.admin_token
        if (url !== '/login') {
            axios.post(Constant.BASE_URL + '/auth-check').then(res => {
                if (res.data.msg === true) {
                    setAuth(res.data.msg)
                } else {
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    window.location.reload()
                }
            }).catch(errors=>{
                // if (errors.response.status === 401){
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    window.location.reload()
                // }
            })
        } else {
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }
    }


    return (
        <>
            {url === '/login' ?
                <BrowserRouter>
                    <Route exact path={'/login'} component={Login}/>
                </BrowserRouter> :
                <div className="App">
                    <BrowserRouter>
                        <div className="sb-nav-fixed text-start">
                            <Nav/>
                            <div id="layoutSidenav">
                                <SideBar/>
                                <div id="layoutSidenav_content">
                                    <main>
                                        <Auth exact path={'/demo-route'} component={Home}/>
                                        <Auth exact path={'/'} component={Home}/>
                                        <Auth exact path={'/category'} component={CategoryList}/>
                                        <Auth path={'/category/create'} component={CategoryCreate}/>
                                        <Auth path={'/category/edit/:id'} component={CategoryEdit}/>
                                        <Auth exact path={'/property'} component={PropertyList}/>
                                        <Auth path={'/property/create'} component={PropertyCreate}/>
                                        <Auth path={'/property/edit/:id'} component={PropertyEdit}/>
                                        <Auth exact path={'/listing-type'} component={ListingTypeList}/>
                                        <Auth path={'/listing-type/create'} component={ListingTypeCreate}/>
                                        <Auth path={'/listing-type/edit/:id'} component={ListingTypeEdit}/>
                                        <Auth exact path={'/property-type'} component={PropertyTypeList}/>
                                        <Auth path={'/property-type/create'} component={PropertyTypeCreate}/>
                                        <Auth path={'/property-type/edit/:id'} component={PropertyTypeEdit}/>
                                        <Auth exact path={'/amenities'} component={AmenitiesList}/>
                                        <Auth  path={'/amenities/create'} component={AmenitiesCreate}/>
                                        <Auth  path={'/amenities/edit/:id'} component={AmenitiesEdit}/>
                                        <Auth exact path={'/guest-favorites'} component={GuestFavoritesList}/>
                                        <Auth  path={'/guest-favorites/create'} component={GuestFavoritesCreate}/>
                                        <Auth  path={'/guest-favorites/edit/:id'} component={GuestFavoritesEdit}/>
                                        <Auth exact path={'/safety-items'} component={SafetyItemList}/>
                                        <Auth  path={'/safety-items/create'} component={SafetyItemCreate}/>
                                        <Auth  path={'/safety-items/edit/:id'} component={SafetyItemEdit}/>
                                        <Auth  path={'/listing-list'} component={ListingList}/>

                                    </main>
                                    <Footer/>
                                </div>
                            </div>
                        </div>
                    </BrowserRouter>
                </div>
            }
        </>
    );
}

export default App;

// order percentage -> 15%
// safety deposit - refundable ->
// terms & conditions should be individual
