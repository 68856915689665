import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import loader from "../../../Assets/Image/loader/loader.svg";
import {Link, useHistory, useParams} from "react-router-dom";
import default_icon from "../../../Assets/Image/8.jpg";
import axios from "axios";
import Constant from "../../../Constant";
import GlobalFunctions from "../../../GobalFunctions";
import $ from "jquery";
import Swal from "sweetalert2";

const ListingTypeEdit = () => {

    const params = useParams()
    const history = useHistory()
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [icon, setIcon] = useState(default_icon)
    const [isLoading, setIsLoading] = useState(false)

    const getListingType = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/listing-type/${params.id}`).then(res => {
            setIcon(res.data.data.icon)
            setInput(prevState => ({...prevState, name: res.data.data.name}))
            setInput(prevState => ({...prevState, slug: res.data.data.slug}))
            setInput(prevState => ({...prevState, status: res.data.data.status}))
            setInput(prevState => ({...prevState, order_by: res.data.data.order_by}))
            setIsLoading(false)
        })
    }
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'name') {
            let value = GlobalFunctions.slugFormatter(e.target.value)
            setInput(prevState => ({...prevState, slug: value}))
        }
    }

    const triggerImage = () => {
        $('#image_upload_input').trigger('click')
    }

    const handleImageUpload = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setIcon(reader.result)
            setInput(prevState => ({...prevState, icon: reader.result}))
        }
        reader.readAsDataURL(file)
    }

    const handleListingTypeStore = () => {
        setIsLoading(true)
        setErrors([])
        axios.put(`${Constant.BASE_URL}/listing-type/${params.id}`, input).then(res => {
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 3000
            })
            history.push('/listing-type')
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    useEffect(() => {
        getListingType()
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Edit Listing Type | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'Edit Listing Type'}/>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card pb-2 mb-5 my-card position-relative">
                        {isLoading?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div>:null
                        }
                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Edit Listing Type</h4>
                            <Link to={'/listing-type'}><button className={'link-button-card'}><i className="fas fa-list"/></button> </Link>
                        </div>
                        <div className="card-body">
                            <label>
                                Listing Type Name
                                <input
                                    className={errors.name != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter Listing Type Name'}
                                    type={'text'}
                                    name={'name'}
                                    value={input.name}
                                    onChange={handleInput}
                                />
                                {errors.name != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.name[0]}</small></p> : null}
                            </label>
                            <label>
                                Listing Type Slug
                                <input
                                    className={errors.slug != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter Listing Type Slug'}
                                    type={'text'}
                                    name={'slug'}
                                    value={input.slug}
                                    onChange={handleInput}
                                />
                                {errors.slug != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.slug[0]}</small></p> : null}
                            </label>
                            <label>
                                Order By
                                <input
                                    className={errors.order_by != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter Listing Type Serial'}
                                    type={'number'}
                                    name={'order_by'}
                                    value={input.order_by}
                                    onChange={handleInput}
                                />
                                {errors.order_by != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/> {errors.order_by[0]}</small></p> : null}
                            </label>
                            <label>
                                Status
                                <select
                                    className={errors.order_by != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                    name={'status'}
                                    value={input.status}
                                    onChange={handleInput}
                                >
                                    <option>Select Status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                                {errors.status != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/> {errors.status[0]}</small></p> : null}
                            </label>
                            <label>
                                Listing Type Icon
                                <input
                                    className={'d-none'}
                                    type={'file'}
                                    name={'icon'}
                                    id={'image_upload_input'}
                                    onChange={handleImageUpload}
                                />
                                {errors.icon != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/> {errors.icon[0]}</small></p> : null}
                            </label>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="category-icon-container">
                                        <i onClick={triggerImage} className="fas fa-camera"></i>
                                        <img src={icon} className={'img-thumbnail'} alt={'image'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-grid">
                                <button onClick={handleListingTypeStore} className={'create-button'}
                                        dangerouslySetInnerHTML={{__html: isLoading ? '<div class="spinner-border spinner-border-sm"></div>' : 'Update Listing Type'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingTypeEdit;
