import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constant from "../../../Constant";
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import loader from "../../../Assets/Image/loader/loader.svg";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";

const ListingTypeList = () => {
    const [listings, setListings] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [startFrom, setStartFrom] = useState(1)
    const [sortInput, setSortInput] = useState({
        column:'order_by',
        direction:'asc',
        search: '',
    })
    const [clearFilter, setClearFiler] =useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [isSortLoading, setIsSortLoading] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [isFilterLoading, setIsFilterLoading] = useState(false)

    const getListingTypes = ( pageNumber = 1, type= 'regular') => {
        setIsLoading(true)
        if(type == 'sort'){
            setIsSortLoading(true)
        }else if(type == 'search'){
            setIsSearchLoading(true)
            console.log('search')
        }else if(type == 'filter'){
            setIsFilterLoading(true)
        }
        setActivePage(pageNumber)
        axios.get(`${Constant.BASE_URL}/listing-type?page=${pageNumber}&column=${sortInput.column}&direction=${sortInput.direction}&search=${sortInput.search}`).then(res => {
            setListings(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setStartFrom(res.data.meta.from)
            setIsLoading(false)
            setIsSortLoading(false)
            setIsSearchLoading(false)
            setIsFilterLoading(false)
        })
    }

    const handleListingTypeDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Listing Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/listing-type/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListingTypes()
                })
            }
        })
    }
    const handleClearFilters = () => {
        setSortInput({
            column:'order_by',
            direction:'asc',
            search: '',
        })
        setClearFiler(clearFilter+1)
    }

    const handleSortValue = (e) => {
        setSortInput(prevState => ({...prevState,[e.target.name] : e.target.value}))
    }
    const handleSort = () => {
        getListingTypes(1, 'sort')
    }
    const handleSearch = () => {
        getListingTypes(1,'search')
    }
    useEffect(() => {
        getListingTypes()
    }, [])

    useEffect(() => {
        getListingTypes(1,'filter')
    }, [clearFilter]);

    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Listing Type List | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'Listing Type List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div>:null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Listing Type List</h4>
                            <Link to={'/listing-type/create'}>
                                <button className={'link-button-card'}><i className="fas fa-plus"/></button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <label>
                                            Order By
                                            <select
                                                className={'form-select form-select-sm'}
                                                onChange={handleSortValue}
                                                value={sortInput.column}
                                                name={'column'}
                                            >
                                                <option value={0}>Select Column</option>
                                                <option value={'name'}>Name</option>
                                                <option value={'slug'}>Slug</option>
                                                <option value={'slug_id'}>Slug ID</option>
                                                <option value={'status'}>Status</option>
                                                <option value={'order_by'}>Order By</option>
                                                <option value={'admin_id'}>Created By</option>
                                                <option value={'created_at'}>Created At</option>
                                                <option value={'updated_at'}>Updated At</option>
                                            </select>
                                        </label>
                                        <label className={'ms-1'}>
                                            Direction
                                            <select
                                                className={'form-select form-select-sm'}
                                                onChange={handleSortValue}
                                                value={sortInput.direction}
                                                name={'direction'}
                                            >
                                                <option value={0}>Select Direction</option>
                                                <option value={'asc'}>Ascending</option>
                                                <option value={'desc'}>Descending</option>
                                            </select>
                                        </label>
                                        <button onClick={handleSort} className={'sort-button ms-1'} dangerouslySetInnerHTML={{__html: isSortLoading ? '<div class="spinner-border spinner-border-sm"></div>' : '<i class="fas fa-sort"></i> Sort'}} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <label>
                                            Search Listing Type
                                            <span className="input-group">
                                            <input
                                                type={'search'}
                                                name={'search'}
                                                className={'form-control form-control-sm'}
                                                value={sortInput.search}
                                                onChange={handleSortValue}
                                            />
                                            <button onClick={handleSearch} className="input-group-text btn-sm search-button" dangerouslySetInnerHTML={{__html: isSearchLoading ? '<div class="spinner-border spinner-border-sm"></div>' : '<i class="fas fa-search"></i> <span class="ms-2">Search</span>'}} />
                                        </span>
                                        </label>
                                        <button onClick={handleClearFilters} className={'clear-filters ms-1'} dangerouslySetInnerHTML={{__html: isFilterLoading ? '<div class="spinner-border spinner-border-sm"></div>' : '<i class="fas fa-hand-sparkles"></i> Clear Filters'}}/>
                                    </div>
                                </div>
                            </div>
                            {Object.keys(listings).length > 0 ?
                                <table className={'table table-sm table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Name</th>
                                        <th>Slug</th>
                                        <th>Status</th>
                                        <th>Order By</th>
                                        <th>Created By</th>
                                        <th>Date Time</th>
                                        <th>Icon</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {listings.map((listing, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle'}>{startFrom + (index++)}</td>
                                            <td>{listing.name}</td>
                                            <td>
                                                <p className={'text-success'}><small>{listing.slug}</small></p>
                                                <p className={'text-info'}><small>{listing.slug_id}</small></p>
                                            </td>
                                            <td>{listing.status}</td>
                                            <td>{listing.order_by}</td>
                                            <td><Link to={'/'}>{listing.created_by}</Link></td>
                                            <td>
                                                <p className={'text-success'}><small>{listing.created_at}</small></p>
                                                <p className={'text-info'}><small>{listing.updated_at}</small></p>
                                            </td>
                                            <td><img src={listing.icon} alt={'Listing Icon'} className={'table-category-icon'}/></td>
                                            <td>
                                                <Link to={`/listing-type/edit/${listing.id}`}>
                                                    <button className={'btn btn-sm btn-warning'}><i className={'fas fa-edit'}/></button>
                                                </Link>
                                                <button onClick={()=>handleListingTypeDelete(listing.id)} className={'btn btn-sm btn-danger ms-1'}><i className={'fas fa-trash'}/></button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>:
                                <div className={'text-center'}><p className={'text-danger'}>No Data Found</p></div>
                            }

                        </div>

                            <div className="card-footer">
                                <nav className={'pagination-sm'}>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={getListingTypes}
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                        firstPageText="First"
                                        lastPageText="Last"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </nav>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingTypeList;
